import * as React from "react"
import { useStaticQuery, graphql, Link } from "gatsby"
import { GatsbyImage, StaticImage, getImage } from "gatsby-plugin-image"
import loadable from '@loadable/component'
import pMinDelay from 'p-min-delay'
import { PhoneIcon } from "@heroicons/react/solid"
import { IoIosMail } from "react-icons/io"
import styled from "styled-components"
import {
  FaLinkedin,
  FaTiktok,
  FaYoutubeSquare,
  FaFacebookSquare,
  FaInstagramSquare,
  FaTwitterSquare,
} from "react-icons/fa"

import LogoFooter from "./logo-footer"

const AccessibeWidget = loadable(() =>
  pMinDelay(import('./common/AccessibeWidget'), 5000)
)

const StyledDiv = styled.div`
  border-width: 1px;
  border-color: transparent;

  &:hover {
    border-width: 1px;
    border-color: #bb0000;
  }
`

const colStyle = "flex flex-col space-y-4 z-20"
const h2Style =
  "text-white uppercase text-sm font-semibold mb-6 border-b pb-3 border-site-red"
const linkStyle = "text-sm text-white hover:underline hover:text-site-red"

const FooterNewUpdate = () => {
  const data = useStaticQuery(graphql`
    query FooterNewUpdateQuery {
      site {
        siteMetadata {
          title
        }
      }
      prismicGeneralSettings {
        data {
          drop_down_menu_name_1
          drop_down_menu_name_2
          drop_down_menu_name_3
          footer_column_name_4
          drop_down_menu_1 {
            menu_name
            menu_link {
              uid
              link_type
              url
            }
          }
          drop_down_menu_2 {
            menu_name
            menu_link {
              uid
              link_type
              url
            }
          }
          drop_down_menu_3 {
            menu_name
            menu_link {
              uid
              link_type
              url
            }
          }
          tab_name_1
          tab_name_2
          tab_name_3
          tab_link_3 {
            link_type
            url
          }
          static_menu
          footer_logo_2 {
            gatsbyImageData(width: 120)
          }
          office_hours
          phone
          tiktok
          youtube
          instagram
          linkedin
          email
          facebook
          terms_link_name
          terms_link {
            uid
          }
          privacy_link_name
          privacy_link {
            uid
          }
        }
      }
      prismicContactPage {
        data {
          location_address
          location_address_url
        }
      }
    }
  `)

  const doc = data.prismicGeneralSettings
  const docContact = data.prismicContactPage
  const menuOneList = doc.data.drop_down_menu_1
  const menuTwoList = doc.data.drop_down_menu_2
  const menuThreeList = doc.data.drop_down_menu_3
  const image = getImage(doc.data.footer_logo_2)

  return (
    <>
      <footer className="w-full bg-site-black pt-10 md:pt-20 pb-10">
        <div className="w-11/12 max-w-screen-xl flex flex-col md:flex-row items-start justify-center space-x-0 md:space-x-12 mx-auto mb-8">
          <div className="w-full xl:w-9/12 grid grid-cols-1 lg:grid-cols-2 xl:grid-cols-3 gap-12 mb-16 md:mb-0">
            <div className={colStyle}>
              <h2 className={h2Style}>
                {doc.data.drop_down_menu_name_1
                  ? doc.data.drop_down_menu_name_1
                  : "Menu Name"}
              </h2>
              {menuOneList.map((item, index) => {
                return item.menu_link.link_type === "Document" ||
                  item.menu_link.link_type === "Any" ? (
                  <Link
                    key={index}
                    to={item.menu_link.uid ? `/${item.menu_link.uid}` : "/"}
                    className={linkStyle}
                  >
                    {item.menu_name ? item.menu_name : "Menu Item"}
                  </Link>
                ) : item.menu_link.link_type === "Web" ||
                  item.menu_link.link_type === "Media" ? (
                  <a
                    href={item.menu_link.url}
                    target="_blank"
                    rel="noopener noreferrer"
                    className={linkStyle}
                  >
                    {item.menu_name ? item.menu_name : "Menu Item"}
                  </a>
                ) : null
              })}
            </div>

            <div className={colStyle}>
              <h2 className={h2Style}>
                {doc.data.drop_down_menu_name_2
                  ? doc.data.drop_down_menu_name_2
                  : "Menu Name"}
              </h2>
              {menuTwoList.map((item, index) => {
                return item.menu_link.link_type === "Document" ||
                  item.menu_link.link_type === "Any" ? (
                  <Link
                    key={index}
                    to={item.menu_link.uid ? `/${item.menu_link.uid}` : "/"}
                    className={linkStyle}
                  >
                    {item.menu_name ? item.menu_name : "Menu Item"}
                  </Link>
                ) : item.menu_link.link_type === "Web" ||
                  item.menu_link.link_type === "Media" ? (
                  <a
                    href={item.menu_link.url}
                    target="_blank"
                    rel="noopener noreferrer"
                    className={linkStyle}
                  >
                    {item.menu_name ? item.menu_name : "Menu Item"}
                  </a>
                ) : null
              })}
            </div>

            <div className={colStyle}>
              <h2 className={h2Style}>
                {doc.data.drop_down_menu_name_3
                  ? doc.data.drop_down_menu_name_3
                  : "Menu Name"}
              </h2>
              {menuThreeList.map((item, index) => {
                return item.menu_link.link_type === "Document" ||
                  item.menu_link.link_type === "Any" ? (
                  <Link
                    key={index}
                    to={item.menu_link.uid ? `/${item.menu_link.uid}` : "/"}
                    className={linkStyle}
                  >
                    {item.menu_name ? item.menu_name : "Menu Item"}
                  </Link>
                ) : item.menu_link.link_type === "Web" ||
                  item.menu_link.link_type === "Media" ? (
                  <a
                    href={item.menu_link.url}
                    target="_blank"
                    rel="noopener noreferrer"
                    className={linkStyle}
                  >
                    {item.menu_name ? item.menu_name : "Menu Item"}
                  </a>
                ) : null
              })}
            </div>
          </div>

          <div className="w-full xl:w-3/12">
            <div className="flex items-start justify-center md:justify-end space-x-3 mb-3">
              <div className="w-36">
                <LogoFooter />
              </div>
            </div>
            <div className="flex flex-col items-center md:items-end">
              <a
                href={`${docContact.data.location_address_url}`}
                target="_blank"
                rel="noopener noreferrer"
              >
                <p className="text-md text-white text-center md:text-right hover:underline hover:text-site-red">{docContact.data.location_address}</p>
              </a>
            </div>
            <div className="flex flex-col items-center md:items-end mt-8 space-y-6">
              <p className="text-white">
                {doc.data.office_hours ? doc.data.office_hours : "Office Hours"}
              </p>
              <p className="flex items-center text-white hover:text-site-red">
                <IoIosMail className="w-6 h-6 mr-2" aria-label="email" />
                <a href={`mailto:${doc.data.email}`} className="hover:underline">
                  {doc.data.email ? doc.data.email : "Email"}
                </a>
              </p>
              <p className="flex items-center text-white hover:text-site-red">
                <PhoneIcon className="w-5 h-5 mr-2" aria-label="phone" />
                <a href={`tel:${doc.data.phone}`} className="hover:underline">
                  {doc.data.phone ? doc.data.phone : "Phone"}
                </a>
              </p>

              <div className="flex items-center space-x-3">
                <a
                  href={
                    doc.data.linkedin
                      ? doc.data.linkedin
                      : "https://www.linkedin.com"
                  }
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <FaLinkedin
                    className="w-8 h-8 text-white hover:text-site-red"
                    aria-label="LinkedIn"
                  />
                </a>
                <a
                  href={
                    doc.data.tiktok ? doc.data.tiktok : "https://www.tiktok.com"
                  }
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <FaTiktok
                    className="w-7 h-7 bg-white hover:bg-site-red text-site-black rounded p-1"
                    aria-label="TikTok"
                  />
                </a>
                <a
                  href={
                    doc.data.youtube
                      ? doc.data.youtube
                      : "https://www.youtube.com"
                  }
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <FaYoutubeSquare
                    className="w-8 h-8 text-white hover:text-site-red"
                    aria-label="Youtube"
                  />
                </a>
                <a
                  href={
                    doc.data.facebook
                      ? doc.data.facebook
                      : "https://www.facebook.com"
                  }
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <FaFacebookSquare
                    className="w-8 h-8 text-white hover:text-site-red"
                    aria-label="Facebook"
                  />
                </a>
                <a
                  href={
                    doc.data.instagram
                      ? doc.data.instagram
                      : "https://www.instagram.com"
                  }
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <FaInstagramSquare
                    className="w-8 h-8 text-white hover:text-site-red"
                    aria-label="Instagram"
                  />
                </a>
                <a
                  href="https://twitter.com/SRA831b"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <FaTwitterSquare
                    className="w-8 h-8 text-white hover:text-site-red"
                    aria-label="Twitter"
                  />
                </a>
              </div>
            </div>
            <div className="flex items-start justify-center md:justify-end space-x-3 mt-8">
              <StyledDiv>
                <a href="https://www.forbes.com/sites/forbesbusinesscouncil/people/vancarlson/?sh=1f818c343e8a" target="_blank" rel="noopener noreferrer">
                  <StaticImage
                    style={{
                      width: "120px",
                      height: "120px",
                    }}
                    src="../images/logos/FBC-Badge.png"
                    width={120}
                    quality={95}
                    formats={["AUTO", "WEBP", "AVIF"]}
                    alt="Forbes Business Council Official Member Badge"                    
                    className="rounded-md"
                    objectFit="contain"
                  />
                </a>
              </StyledDiv>
              <div>
                <GatsbyImage 
                  style={{
                    width: "120px",
                    height: "120px",
                  }}
                  image={image} 
                  alt="RIMS Professional Member Badge"
                  objectFit="contain"
                />
              </div>
            </div>
          </div>
        </div>

        <AccessibeWidget />
      </footer>

      <div className="w-full bg-black py-12">
        <div className="w-11/12 max-w-screen-xl flex flex-col md:flex-row items-center mx-auto">
          <div className="w-full xl:w-1/2 mb-6 md:mb-0">
            <p className="text-white text-center md:text-left text-sm">
              © Copyright {new Date().getFullYear()}{" "}
              {data.site.siteMetadata?.title || `Title`}.
              <br className="lg:hidden" /> All Rights Reserved
            </p>
          </div>

          <div className="w-full xl:w-1/2 max-w-screen-lg text-center md:text-left md:flex md:justify-end mx-auto space-x-8">
            <Link
              to={`/${doc.data.terms_link.uid}`}
              className="text-white text-sm hover:underline hover:text-site-red"
            >
              {doc.data.terms_link_name}
            </Link>
            <Link
              to={`/${doc.data.privacy_link.uid}`}
              className="text-white text-sm hover:underline hover:text-site-red"
            >
              {doc.data.privacy_link_name}
            </Link>
          </div>
        </div>
      </div>
    </>
  )
}

export default FooterNewUpdate
