import React, { useEffect, useState } from "react"
import { graphql } from "gatsby"
import { GatsbyImage, getImage, getSrc } from "gatsby-plugin-image"
import { withPrismicPreview } from "gatsby-plugin-prismic-previews"
import HubspotForm from "react-hubspot-form"
import JotformEmbed from "react-jotform-embed"
import styled from "styled-components"
import useWindowScroll from "../utils/useWindowScroll"
import { window } from "browser-monads"

import LayoutNew from "../components/layout-new"
import Seo from "../components/seo"

const StyledDiv = styled.div`
  min-height: 100vh;
`

const PageNewQualifyFormPageTemplate = ({ data }) => {
  const doc = data.prismicNewQualifyFormPage
  const imgBg = getImage(doc.data.form_section_background_image)
  const imgSrc = getSrc(doc.data.form_section_background_image)
  const imgDefault = getImage(doc.data.imgDefault)
  const winScroll = useWindowScroll()

  const [bgTop, setBgTop] = useState(0)

  useEffect(() => {
    if (winScroll) {
      let top = 0
      let scroll =
        (window.scrollY / document.documentElement.offsetHeight) * 4 * 100
      if (winScroll.scrollY !== bgTop) {
        top = bgTop - scroll - bgTop
      }
      setBgTop(top)
    }
  }, [winScroll.scrollY])

  return (
    <LayoutNew>
      <Seo
        title={doc.data.page_title ? doc.data.page_title : null}
        description={
          doc.data.page_short_description
            ? doc.data.page_short_description
            : null
        }
        image={imgSrc ? imgSrc : null}
        noIndex={doc.data.page_no_index ? true : false}
      />
      <StyledDiv className="relative pt-14 xl:pt-20">
        <div className="fixed h-screen w-full bg-black overflow-hidden -z-10">
          <div
            style={{
              opacity: "0.7",
              position: "relative",
              height: "100vh",
              width: "100%",
            }}
          >
            <div
              className="h-full w-full absolute"
              style={{
                transition: "top 0.5s ease 0s",
                height: "120vh",
                top: `${bgTop}px`,
              }}
            >
              <GatsbyImage
                alt={imgBg.alt ? imgBg.alt : "hero image"}
                image={imgBg ? imgBg : imgDefault}
                formats={["auto", "webp", "avif"]}
                objectFit="cover"
                className="h-full"
              />
            </div>
          </div>
        </div>

        <div
          style={{
            gridArea: "1/1",
            position: "relative",
            placeItems: "center",
            display: "grid",
          }}
        >
          <div className="w-11/12 lg:w-10/12 max-w-screen-lg flex flex-col items-center justify-center px-2 mb-14 xl:mb-24 mt-24">
            <div className="flex flex-col justify-center">
              <h2 className="text-white text-4xl md:text-6xl font-extrabold text-center mt-6 xl:mt-4">
                {doc.data.form_section_title
                  ? doc.data.form_section_title
                  : "Form Section Title"}
              </h2>

              <p className="text-white text-lg md:text-xl text-center mt-6">
                {doc.data.form_section_sub_title
                  ? doc.data.form_section_sub_title
                  : "Form Section Sub-Title"}
              </p>
              {doc.data.form_type === "Hubspot" ? (
                <div className="bg-white w-11/12 max-w-lg mt-12 mx-auto p-8 shadow-lg">
                  <HubspotForm
                    region="na1"
                    portalId="23182726"
                    formId="8232deb7-ba17-4796-b2ac-79d2785dd83a"
                    onReady={() => {
                      const script = document.createElement('script');
                      script.src = 'https://ajax.googleapis.com/ajax/libs/jquery/3.5.1/jquery.min.js';
                      let jqueryScript = document.getElementsByTagName('script');
                      let src = Array.from(jqueryScript).filter(item => item.src === script.src)
                      if(src.length === 0) {
                        document.body.appendChild(script)
                      }
                    }}
                    onSubmit={() => {
                      setTimeout(() => {
                        window.scrollTo(0, 0)
                      }, 500)
                    }}
                    loading={
                      <div className="italic">Loading Qualify form...</div>
                    }
                  />
                </div>
              ) : (
                <div className="bg-white w-11/12 max-w-lg mt-12 mx-auto pb-8 shadow-lg">
                  <JotformEmbed src="https://form.jotform.com/233397201423147" />
                </div>
              )}
            </div>
          </div>
        </div>
      </StyledDiv>
    </LayoutNew>
  )
}

export default withPrismicPreview(PageNewQualifyFormPageTemplate)

export const PageNewQualifyFormPageTemplateQuery = graphql`
  query ($id: String!) {
    prismicNewQualifyFormPage(id: { eq: $id }) {
      _previewable
      uid
      data {
        page_title
        page_short_description
        page_no_index
        form_section_title
        form_section_sub_title
        form_type
        hubspot_form_id
        jotform_form_url
        form_section_background_image {
          alt
          gatsbyImageData(width: 1920)
        }
      }
    }
    imgDefault: file(relativePath: { eq: "backgrounds/home-hero-bg.jpg" }) {
      childImageSharp {
        gatsbyImageData(width: 1920)
      }
    }
  }
`
