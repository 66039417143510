import React from "react"
import Slider from "react-slick"
import "slick-carousel/slick/slick.css"
import "slick-carousel/slick/slick-theme.css"
import styled from "styled-components"

const StyledSliderCustom = styled(Slider)`
  .slick-prev {
    left: -45px !important;
  }

  .slick-next {
    right: -45px !important;
  }

  .slick-prev, .slick-next {
    width: 40px !important;
    height: 40px !important;
  }

  .slick-prev:before, .slick-next:before {
    font-size: 40px !important;
    color: #bb0000 !important;
  }
`

const Carousel = ({children}) => {
  const sliderSettings = {
    dots: true,
    infinite: true,
    lazyLoad: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          initialSlide: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          dots: false,
        },
      },
    ],
  }

  return (
    <StyledSliderCustom {...sliderSettings}>
      {children}
    </StyledSliderCustom>
  )
}

export default Carousel
