import * as React from "react"
import { CookieNotice } from 'gatsby-cookie-notice';

const SectionCookieNotice = () => {
  return (
    <div className="hidden md:flex items-center justify-center">
      <CookieNotice
        backgroundClasses="bg-site-gray-dark opacity-90 flex flex-col lg:flex-row items-center md:justify-between py-1 px-2"
        backgroundWrapperClasses="w-9/12 md:w-7/12 xl:w-4/12 fixed bottom-0 z-20 py-0 mx-auto"
        acceptButtonClasses="border border-white hover:bg-site-red-hover hover:border-site-red-hover text-white text-sm md:text-base font-semibold mt-2 lg:mt-0 px-6 py-0"
        acceptButtonText="OK"
        declineButton={false}
        personalizeButtonEnable={false}
      >
        <p className="text-white text-sm md:text-base text-center xl:text-left">By using this website, you agree to our use of cookies.</p>
      </CookieNotice>
    </div>
  )
}

export default SectionCookieNotice