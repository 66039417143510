exports.linkResolver = (doc) => {
  switch (doc.type) {
    case "blog_post":
      return `/blog/${doc.uid}`;

    case "symposium":
      return `/${doc.uid}`;

    case "new_qualify_form_page":
      return `/${doc.uid}`;

    default:
      if (!doc.uid) return "/";
      return doc.uid;
  }
};