import * as React from "react"
import { useStaticQuery, graphql, Link } from 'gatsby'
import { GatsbyImage, getImage } from "gatsby-plugin-image"

const HeroSection = ({ imgSrc, title, withButton, buttonLinkType, buttonLinkUrl, buttonLinkUid, buttonName, redBubble, redBubbleText }) => {
  const data = useStaticQuery(graphql`
    query HeroSectionQuery {
      imgDefault: file(relativePath: { eq: "backgrounds/home-hero-bg.jpg" }) {
        childImageSharp {
          gatsbyImageData(
            width: 1920
          )
        }
      }
    }
  `)

  const imgDefault = getImage(data.imgDefault)

  return (
    <>
      <div className={`${withButton ? "" : "-z-10"} relative pt-14 xl:pt-20`} style={{ display: "grid", background: "#000000", minHeight: "400px" }}>
        <GatsbyImage
          style={{
            gridArea: "1/1",
            objectFit: "cover",
            opacity: "0.7",
            height: 400,
          }}
          alt="hero background"
          image={imgSrc ? imgSrc : imgDefault}
          formats={["auto", "webp", "avif"]}
        />

        <div
          style={{
            gridArea: "1/1",
            position: "relative",
            placeItems: "center",
            display: "grid",
          }}
        >
          {title
            ?
            <div className="w-11/12 lg:w-10/12 max-w-screen-lg flex flex-col items-center justify-center px-2">
              <div className="flex flex-col justify-center">
                <h2 className="text-white text-4xl md:text-6xl font-extrabold text-center mt-4">
                  {title}
                </h2>

                {withButton
                  ?
                  <div className="w-full flex items-center justify-center mt-8">
                    {buttonLinkType === "Document" || buttonLinkType === "Any"
                      ?
                        <Link to={buttonLinkUid ? `/${buttonLinkUid}` : "/"}>
                          <button className="w-full bg-site-red hover:bg-site-red-hover text-base md:text-lg text-white uppercase tracking-wider px-8 py-3">
                            {buttonName ? buttonName : "Button Name"}
                          </button>
                        </Link>
                      :
                      buttonLinkType === "Web" || buttonLinkType === "Media"
                      ?
                        <a
                          href={buttonLinkUrl}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          <button className="w-full bg-site-red hover:bg-site-red-hover text-base md:text-lg text-white uppercase tracking-wider px-8 py-3">
                            {buttonName ? buttonName : "Button Name"}
                          </button>
                        </a>
                      :
                      null
                    }
                  </div>
                  :
                  null
                }
              </div>
            </div>
            :
            null
          }
        </div>
      </div>

      {withButton
        ?
        null
        :
        redBubble
        ?
          <div>
            <div className="w-11/12 lg:w-10/12 xl:w-11/12 max-w-screen-lg bg-red-800 mx-auto py-12 px-6 md:px-16 -mt-16 z-20 rounded-3xl shadow-lg">
              <h2 className="text-white font-medium text-center text-lg md:text-xl">
                {redBubbleText ? redBubbleText : "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aenean auctor sagittis tellus, sit amet auctor velit tincidunt commodo. Phasellus vel lacinia justo. Sed gravida maximus maximus. Donec tellus libero, aliquam in mauris ac, vestibulum bibendum arcu. Nam tristique a orci ornare vestibulum."}
              </h2>
            </div>
          </div>
        :
        null
      }
    </>
  )
}

export default HeroSection
