import React, { useEffect } from 'react';
import PropTypes from 'prop-types';

import HeaderFull from './header/header-full';
import FooterNewUpdate from './footer-new-update';
import SectionCookieNotice from './sections/section-cookie-notice';
import SectionCookieNoticeMobile from './sections/section-cookie-notice-mobile';

const LayoutUpdate = ({ children }) => {
  useEffect(() => {
    if (typeof window !== 'undefined' && window.Chatlio) {
      window.Chatlio.init('d932bc71-e6bc-4d0a-7bfc-22ebd2283d28');
    }
  }, []);

  const openChat = (e) => {
    e.preventDefault();
    if (typeof window !== 'undefined' && window._chatlio) {
      window._chatlio.open();
    } else {
      console.error('Chatlio not initialized');
    }
  };

  return (
    <>
      <HeaderFull />
      <div>
        <main>{children}</main>
      </div>
      <chatlio-widget widgetid="d932bc71-e6bc-4d0a-7bfc-22ebd2283d28"></chatlio-widget>
      <FooterNewUpdate />
      <SectionCookieNotice />
      <SectionCookieNoticeMobile />
    </>
  );
};

LayoutUpdate.propTypes = {
  children: PropTypes.node.isRequired,
};

export default LayoutUpdate;